import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91adId_93TzSGfE1gjoMeta } from "/opt/build/repo/pages/fb-campaign/[adId].vue?macro=true";
import { default as _91_46_46_46slug_93MNmlUVAmZ3Meta } from "/opt/build/repo/pages/in-app/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_937GSwhM3iV8Meta } from "/opt/build/repo/pages/invite/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug___en___default",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug___sv",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/sv/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug___pt",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/pt/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91adId_93TzSGfE1gjoMeta?.name ?? "fb-campaign-adId___en___default",
    path: _91adId_93TzSGfE1gjoMeta?.path ?? "/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    alias: _91adId_93TzSGfE1gjoMeta?.alias || [],
    redirect: _91adId_93TzSGfE1gjoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue").then(m => m.default || m)
  },
  {
    name: _91adId_93TzSGfE1gjoMeta?.name ?? "fb-campaign-adId___en",
    path: _91adId_93TzSGfE1gjoMeta?.path ?? "/en/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    alias: _91adId_93TzSGfE1gjoMeta?.alias || [],
    redirect: _91adId_93TzSGfE1gjoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue").then(m => m.default || m)
  },
  {
    name: _91adId_93TzSGfE1gjoMeta?.name ?? "fb-campaign-adId___de",
    path: _91adId_93TzSGfE1gjoMeta?.path ?? "/de/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    alias: _91adId_93TzSGfE1gjoMeta?.alias || [],
    redirect: _91adId_93TzSGfE1gjoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue").then(m => m.default || m)
  },
  {
    name: _91adId_93TzSGfE1gjoMeta?.name ?? "fb-campaign-adId___sv",
    path: _91adId_93TzSGfE1gjoMeta?.path ?? "/sv/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    alias: _91adId_93TzSGfE1gjoMeta?.alias || [],
    redirect: _91adId_93TzSGfE1gjoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue").then(m => m.default || m)
  },
  {
    name: _91adId_93TzSGfE1gjoMeta?.name ?? "fb-campaign-adId___pt",
    path: _91adId_93TzSGfE1gjoMeta?.path ?? "/pt/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    alias: _91adId_93TzSGfE1gjoMeta?.alias || [],
    redirect: _91adId_93TzSGfE1gjoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MNmlUVAmZ3Meta?.name ?? "in-app-slug___en___default",
    path: _91_46_46_46slug_93MNmlUVAmZ3Meta?.path ?? "/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    alias: _91_46_46_46slug_93MNmlUVAmZ3Meta?.alias || [],
    redirect: _91_46_46_46slug_93MNmlUVAmZ3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MNmlUVAmZ3Meta?.name ?? "in-app-slug___en",
    path: _91_46_46_46slug_93MNmlUVAmZ3Meta?.path ?? "/en/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    alias: _91_46_46_46slug_93MNmlUVAmZ3Meta?.alias || [],
    redirect: _91_46_46_46slug_93MNmlUVAmZ3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MNmlUVAmZ3Meta?.name ?? "in-app-slug___de",
    path: _91_46_46_46slug_93MNmlUVAmZ3Meta?.path ?? "/de/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    alias: _91_46_46_46slug_93MNmlUVAmZ3Meta?.alias || [],
    redirect: _91_46_46_46slug_93MNmlUVAmZ3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MNmlUVAmZ3Meta?.name ?? "in-app-slug___sv",
    path: _91_46_46_46slug_93MNmlUVAmZ3Meta?.path ?? "/sv/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    alias: _91_46_46_46slug_93MNmlUVAmZ3Meta?.alias || [],
    redirect: _91_46_46_46slug_93MNmlUVAmZ3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MNmlUVAmZ3Meta?.name ?? "in-app-slug___pt",
    path: _91_46_46_46slug_93MNmlUVAmZ3Meta?.path ?? "/pt/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    alias: _91_46_46_46slug_93MNmlUVAmZ3Meta?.alias || [],
    redirect: _91_46_46_46slug_93MNmlUVAmZ3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_937GSwhM3iV8Meta?.name ?? "invite-slug___en___default",
    path: _91_46_46_46slug_937GSwhM3iV8Meta?.path ?? "/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    alias: _91_46_46_46slug_937GSwhM3iV8Meta?.alias || [],
    redirect: _91_46_46_46slug_937GSwhM3iV8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_937GSwhM3iV8Meta?.name ?? "invite-slug___en",
    path: _91_46_46_46slug_937GSwhM3iV8Meta?.path ?? "/en/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    alias: _91_46_46_46slug_937GSwhM3iV8Meta?.alias || [],
    redirect: _91_46_46_46slug_937GSwhM3iV8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_937GSwhM3iV8Meta?.name ?? "invite-slug___de",
    path: _91_46_46_46slug_937GSwhM3iV8Meta?.path ?? "/de/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    alias: _91_46_46_46slug_937GSwhM3iV8Meta?.alias || [],
    redirect: _91_46_46_46slug_937GSwhM3iV8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_937GSwhM3iV8Meta?.name ?? "invite-slug___sv",
    path: _91_46_46_46slug_937GSwhM3iV8Meta?.path ?? "/sv/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    alias: _91_46_46_46slug_937GSwhM3iV8Meta?.alias || [],
    redirect: _91_46_46_46slug_937GSwhM3iV8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_937GSwhM3iV8Meta?.name ?? "invite-slug___pt",
    path: _91_46_46_46slug_937GSwhM3iV8Meta?.path ?? "/pt/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    alias: _91_46_46_46slug_937GSwhM3iV8Meta?.alias || [],
    redirect: _91_46_46_46slug_937GSwhM3iV8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue").then(m => m.default || m)
  }
]